import { useState, useMemo } from 'react'
import VideoProject from './VideoProject'
import VideoNameTag from './VideoNameTag'

export default function VideoBox(props) {
  const {
    stream,
    muted,
    isLocal,
    username,
    mutedAudio,
    mutedVideo,
  } = props

  const [fullscreen, setFullscreen] = useState(false)

  const videoStyle = useMemo(() => {
    return fullscreen ? {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1000,
      backgroundColor: 'black',
      height: '100%',
      width: '100%',
    } : {
      backgroundColor: mutedVideo ? 'black' : '',
      height: '100%',
    }
  }, [fullscreen, mutedVideo])

  const nameTagStyle = useMemo(() => {
    if (mutedVideo) {
      return {
        bottom: '50%',
        transform: 'translate(-50%, 50%)',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
      }
    }

    if (fullscreen) {
      return {
        position: 'fixed',
        bottom: '10%',
        left: '50%',
        zIndex: 1001,
        transform: 'translateX(-50%)',
      }
    }
  }, [fullscreen, mutedVideo])

  function toggleFullscreen() {
    if (isLocal) return
    setFullscreen(!fullscreen)
  }

  return (
    <div style={videoStyle} onDoubleClick={toggleFullscreen}>
      <VideoProject
        stream={stream}
        muted={muted}
      ></VideoProject>

      { username && <VideoNameTag
        username={username}
        fullscreen={fullscreen}
        style={nameTagStyle}
        mutedVideo={mutedVideo}
        mutedAudio={mutedAudio}
        ></VideoNameTag> }
    </div>
  )
}