const appHost = process.env.REACT_APP_WEBSOCKET_HOST
const sfuHost = process.env.REACT_APP_SFU_HOST
const prefix = process.env.REACT_APP_URL_PREFIX

const signaling =  () => {
  return `${appHost}${prefix}/ws`
}

const sfu = () => {
  return `${sfuHost}${prefix}/sfu`
}

const generateGuestURL = () => {
  return `${appHost}${prefix}/generateGuestURL`
}

const api = {
  signaling,
  sfu,
  generateGuestURL,
}

export default api