import {
  Container,
  CssBaseline,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useMemo, useRef, useState } from 'react'
import { store } from 'react-notifications-component'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BottomAppBar from '../components/BottomAppBar'
import NameList from '../components/NameList'
import VideoBox from '../components/VideoBox'
import ParticipantConfirmBox from '../components/ParticipantConfirmBox'
import useSFU from '../hooks/useSFU'
import useWebSocket from '../hooks/useWebSocket'
import Background from '../images/background.jpg'
import intent from '../intent'
import actions from '../store/actions'

const useStyles = makeStyles({
  rootContainer: {
    backgroundImage: `url(${Background})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
  },
  paper: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    maxHeight: '100vh',
  },
})

function Room(props) {
  const {
    id,
    roomId,
    username,
    roomKey,
    guestNumber,
    dispatch,
  } = props

  const history = useHistory()
  const classes = useStyles()
  const [turnServerConfig, setTurnServerConfig] = useState(null)
  const [room, setRoom] = useState({
    host: {},
    guestList: [],
    waitingList: [],
  })
  const [waitingCount, setWaitingCount] = useState(0)
  const [firstUpdate, setFirstUpdate] = useState(true)
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const hasSendStreamId = useRef(false)

  const {
    initSFUConnection,
    initStream,
    localStream,
    streams,
    publishStream,
    muteVideo,
    muteAudio,
    disconnectSFU,
  } = useSFU()

  const isHost = useMemo(() => {
    return id === (room.host && room.host.id)
  }, [id, room.host])

  const [isWhatsappLoading, setIsWhatsappLoading] = useState(false)

  const [isMute, setIsMute] = useState(false)
  const [isDisableVideo, setIsDisableVideo] = useState(false)
  const [isNameListVisible, setIsNameListVisible] = useState(false)
  const [hideSelf, setHideSelf] = useState(false)
  const [hideBottomBar, setHideBottomBar] = useState(false)

  const initializedWebSocket = useRef(false)
  const {
    websocket,
    connectWebSocket,
    disconnectWebSocket,
    subscribeOnOpen,
    unsubscribeOnOpen,
    subscribeOnClose,
    unsubscribeOnClose,
    subscribeOnError,
    unsubscribeOnError,
    subscribeOnMessage,
    unsubscribeOnMessage,
    sendWebsocketMessage,
  } = useWebSocket()

  const toggleMute = () => {
    const muted = !isMute

    sendWebsocketMessage(intent.createUpdateMutedAudioIntent(id, muted))
    setIsMute(muted)
    muteAudio(muted)
  }

  const toggleVideo = () => {
    const muted = !isDisableVideo

    sendWebsocketMessage(intent.createUpdateMutedVideoIntent(id, muted))
    setIsDisableVideo(muted)
    muteVideo(muted)

    if (muted) {
      setHideSelf(true)
    } else {
      setHideSelf(false)
    }
  }

  const toggleNameList = () => setIsNameListVisible(!isNameListVisible)

  const { t, i18n } = useTranslation('main')

  // onSocketMessage handles socket onmessage event
  function onSocketMessage(socketMessage) {
    switch (socketMessage.intent) {
      case intent.informId:
        dispatch(actions.login(socketMessage.data.id, username, roomId, roomKey))
        sendWebsocketMessage(intent.createGetTurnServeConfigIntent(socketMessage.data.id))
        break
      case intent.updateRoomInfo:
        setRoom(socketMessage.data.room)
        setWaitingCount(socketMessage.data.room.waitingList.length)

        if(firstUpdate) { // accept all connections
          setFirstUpdate(false)
        }
        break
      case intent.getTurnServeConfig:
        setTurnServerConfig(socketMessage.data)
        break
      case intent.disconnect:
        kickedOutByHost()
        break
      case intent.sentWhatsapp:
        setIsWhatsappLoading(false)
        if (socketMessage.data?.error) {
          store.addNotification(createNotificationConfig(t('invite.whatsappError'), 'warning'))
        } else {
          store.addNotification(createNotificationConfig(t('invite.whatsappSuccess'), 'success'))
        }
        break
      case intent.decline:
        kickedOutByHost()
        history.push('/rejected')
        break
      case intent.terminate:
        disconnect()
        break
      default:
    }
  }

  // onSocketClose handles socket onclose event
  function onSocketClose(e) {
    console.log('socket closed:', e)
    disconnect()
  }

  // onSocketError handles socket onerror event
  function onSocketError(e) {
    console.error('socket error:', e)
    disconnect()
  }

  // kickedOutByHost shows the message and disconnects the user after kicked out by the host
  function kickedOutByHost() {
    store.addNotification(createNotificationConfig(t('room.disconnectedByHost')))
    disconnect()
  }

  // disconnect clears all the resources
  function disconnect() {
    if (isDisconnecting) {
      return
    } else {
      setIsDisconnecting(true)
    }

    disconnectSFU()
    disconnectWebSocket(id)

    dispatch(actions.logout())

    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.get('itm') !== '1') {
      window.close()
    }

    // history.push('/')
  }

  // createNotificationConfig creates an object of the notification config
  function createNotificationConfig(message, type = 'default') {
    return {
      message,
      type,
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true,
        click: true,
        touch: true,
        showIcon: true,
      },
    }
  }

  // waitingUserClickedHandler sends a message to server
  // to move a user from waiting list to the user list
  function waitingUserClickedHandler(userId) {
    if (!room.host || room.host.id !== id) {
      return
    }

    sendWebsocketMessage(intent.createAcceptConnectIntent(id, userId))
  }

  // disconnectUserHandler handles the host trying to disconnect other use
  function disconnectUserHandler(userId, slient) {
    if (!room.host || room.host.id !== id) {
      return
    }

    if (slient || window.confirm('Disconnect user from this meeting?')) {
      sendWebsocketMessage(intent.createDisconnectUserIntent(id, userId))
    }
  }

  // send intent to send whatsapp to guest
  function requestInvitationHandler(guestNumber, guestName, guestEmail) {
    if (!room.host || room.host.id !== id) {
      return
    }

    if ((!guestNumber || guestNumber === '852') && !guestEmail) {
      return
    }

    if (guestName) {
      setIsWhatsappLoading(true)
      store.addNotification(createNotificationConfig(t('room.sendingInvitation'), 'info'))

      if (guestNumber !== '852') {
        sendWebsocketMessage(intent.createWhatsappIntent(id, null, guestNumber, guestName, i18n.language))
      }

      if (guestEmail) {
        sendWebsocketMessage(intent.createEmailIntent(id, null, guestEmail, guestName, i18n.language))
      }

      setIsWhatsappLoading(false)
    }
  }

  function sendStreamId() {
    if (hasSendStreamId.current) {
      return
    }

    if (!websocket || websocket.readyState !== WebSocket.OPEN) {
      return
    }

    if (localStream) {
      hasSendStreamId.current = true
      sendWebsocketMessage(intent.createUpdateStreamIdIntent(id, localStream.id))
    }
  }

  function onSocketOpen() {
    initStream()
  }

  function getVideoGridStyle() {
    const style = {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '100%',
      paddingBottom: '64px',
    }

    if (hideBottomBar) {
      style.paddingBottom = 0
    }

    if (room.guestList.length === 2) {
      style.gridTemplateColumns = '1fr'
    }

    // Style for host
    if (isHost && room.guestList.length === 1) {
      style.gridTemplateColumns = '1fr'
      style.gridTemplateRows = '100%'
      return style
    }

    // Style for guest
    if (!isHost) {
      const inGuestList = room.guestList.find(user => user.id === id)

      if (!inGuestList) {
        style.gridTemplateColumns =  '1fr'
        style.gridTemplateRows = '100%'
        return style
      }
    }

    // Style for hide camera
    if (hideSelf) {
      style.gridTemplateRows = '100%'

      if (room.guestList.length > 3) {
        style.gridTemplateRows = '50% 50%'
      }
    } else {
      style.gridTemplateRows = '15% 85%'

      if (room.guestList.length > 3) {
        style.gridTemplateRows = '15% 42.5% 42.5%'
      }
    }

    return style
  }

  function showBottomBar() {
    if (!hideBottomBar) return

    setHideBottomBar(false)
  }

  useEffect(() => {
    subscribeOnMessage('Room', onSocketMessage)
    subscribeOnOpen('Room', onSocketOpen)
    subscribeOnClose('Room', onSocketClose)
    subscribeOnError('Room', onSocketError)

    if (!initializedWebSocket.current) {
      initializedWebSocket.current = true
      connectWebSocket(roomId, username, roomKey, guestNumber)
    }

    if (turnServerConfig) {
      if (isHost) {
        initSFUConnection(roomId, username, roomKey, guestNumber, turnServerConfig)

        setTimeout(() => {
          publishStream()
        }, 2000)
      } else if (room.guestList.findIndex(user => user.id === id) >= 0) {
        initSFUConnection(roomId, username, roomKey, guestNumber, turnServerConfig)

        setTimeout(() => {
          publishStream()
        }, 2000)
      }
    }

    sendStreamId()

    return () => {
      unsubscribeOnMessage('Room')
      unsubscribeOnOpen('Room')
      unsubscribeOnClose('Room')
      unsubscribeOnError('Room')
    }
  })

  return (
    <Container className={classes.rootContainer} maxWidth="xl" component="main" style={{ padding: 0}}>
      <CssBaseline />
      <Grid container>
        <Grid item xs={isNameListVisible ? 9 : 12} style={{ padding: 0 }}>
          <div className="video-grid" style={getVideoGridStyle()} onClick={showBottomBar} onDoubleClick={() => {}}>
            { (!!localStream &&  !hideSelf) &&
              <div className="video-grid-local-stream">
                <VideoBox
                  key={localStream.id}
                  stream={localStream}
                  muted={true}
                  isLocal={true}
                  username=""
                />
              </div>
            }

            {
              room.guestList.map(user => {
                if (user.streamId in streams.current) {
                  return (
                    <div key={user.streamId} className="video-grid-remote-stream">
                      <VideoBox
                        stream={streams.current[user.streamId]}
                        muted={false}
                        isLocal={false}
                        username={user.name}
                        mutedAudio={user.mutedAudio}
                        mutedVideo={user.mutedVideo}
                      />
                    </div>
                  )
                }

                return false
              })
            }
          </div>
        </Grid>
        { isNameListVisible &&
          <Grid item xs={3} style={{
            backgroundColor: 'rgba(150, 150, 150, 0.1)',
          }}>
            { !!room.guestList.length &&
              <NameList
                title={t('room.connected')}
                userId={id}
                host={room.host}
                userList={room.guestList}
                onNameClicked={disconnectUserHandler}
              ></NameList>
            }
            { !!room.waitingList.length &&
              <NameList
                title={t('room.waiting')}
                userId={id}
                host={room.host}
                userList={room.waitingList}
                showControl={isHost}
                onControlAccept={waitingUserClickedHandler}
                onControlDecline={(uid)=>{disconnectUserHandler(uid, true)}}
              ></NameList>
            }
          </Grid>
        }
      </Grid>

      {
        !hideBottomBar &&
        <BottomAppBar
          roomID={roomId}
          isHost={isHost}
          displayMute={!isMute}
          displayDisableVideo={!isDisableVideo}
          onMuteClicked={toggleMute}
          onVideoClicked={toggleVideo}
          onNameListClicked={toggleNameList}
          show={true}
          onDisconnectClicked={disconnect}
          isWhatsappLoading={isWhatsappLoading}
          onSendInvitationClicked={requestInvitationHandler}
          waitingCount={waitingCount}
          onSelfCameraClicked={() => setHideSelf(!hideSelf)}
          displaySelfCamera={!hideSelf}
          onHideBottomBarClicked={() => setHideBottomBar(true)}
        ></BottomAppBar>
      }

      <ParticipantConfirmBox
        isHost={isHost}
        waitingList={room.waitingList}
        onControlAccept={waitingUserClickedHandler}
        onControlDecline={(uid) => { disconnectUserHandler(uid, true) }}
      />
    </Container>
  )
}

export default connect(state => ({
  id: state.id,
  roomId: state.roomId,
  username: state.username,
  roomKey: state.roomKey,
  guestNumber: state.guestNumber,
}))(Room)