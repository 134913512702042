import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  ListSubheader,
  ListItemSecondaryAction,
} from '@material-ui/core'
import {
  Person,
  Call,
  CallEnd,
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  acceptButton: {
    color: 'green',
  },
  declineButton: {
    color: 'red',
  },
})

function UserList({title, userId, host, userList, onNameClicked, showControl, onControlAccept, onControlDecline}) {
  const { t } = useTranslation('main')
  function getSubFix(user) {
    let additionStr = []

    if (userId === user.id) {
      additionStr.push(t('room.you'))
    }

    if (host && host.id === user.id) {
      additionStr.push(t('room.host'))
    }

    additionStr = additionStr.join(', ')

    if (additionStr !== '') {
      additionStr = ` (${additionStr})`
    }

    return additionStr
  }

  const isHost = host && host.id === userId

  const titleEle = <ListSubheader>{title}</ListSubheader>
  const ListItemWithWiderSecondaryAction = withStyles({
    secondaryAction: {
      paddingRight: 96,
    },
  })(ListItem)
  const classes = useStyles()
  const items = userList.map(u => {
    return (
      <ListItemWithWiderSecondaryAction
        dense
        key={u.id}
        disabled={isHost && userId === u.id}
        button={isHost && onNameClicked !== undefined}
        onClick={onNameClicked ? () => onNameClicked && onNameClicked(u.id) : undefined}
      >
        <ListItemIcon>
          <Person></Person>
          { u.mutedVideo ? <VideocamOff color="secondary" /> : <Videocam /> }
          { u.mutedAudio ? <MicOff color="secondary" /> : <Mic /> }
        </ListItemIcon>
        <ListItemText primary={u.name + getSubFix(u)} secondary={null}></ListItemText>
        { showControl &&
        <ListItemSecondaryAction>
          <IconButton onClick={() => onControlAccept && onControlAccept(u.id)} className={classes.acceptButton} aria-label="Accept">
            <Call/>
          </IconButton>
          <span> </span>
          <IconButton onClick={() => onControlDecline && onControlDecline(u.id)} className={classes.declineButton} edge="end" aria-label="Decline">
            <CallEnd/>
          </IconButton>
        </ListItemSecondaryAction>
        }
      </ListItemWithWiderSecondaryAction>
    )
  })

  return (
    <List dense subheader={titleEle}>
      {items}
    </List>
  )
}

export default connect(state => ({
  userId: state.id,
}))(UserList)