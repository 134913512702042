import { createStore } from 'redux'

function user(state = {
  id: '',
  username: '',
  roomId : '',
  roomKey: '',
  guestNumber: '',
}, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        id: action.id,
        username: action.username,
        roomId: action.roomId,
        roomKey: action.roomKey,
        guestNumber: action.guestNumber,
      }
    case 'LOGOUT':
      return {
        ...state,
        id: '',
        username: '',
        roomId : '',
        roomKey: '',
        guestNumber: '',
      }
    default:
      return state
  }
}

let store = createStore(
  user,
  process.env.REACT_APP_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

export default store