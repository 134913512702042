import adapter from 'webrtc-adapter'
import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import store from './store'

import * as serviceWorker from './serviceWorker'

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import CircularProgress from '@material-ui/core/CircularProgress'

import App from './App'

import './i18n'
import { Suspense } from 'react'

console.log(adapter.browserDetails.browser)

if (adapter.browserDetails.browser === 'firefox') {
  alert('Firefox is not supported')
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ReactNotification />
        <Suspense fallback={<CircularProgress size={50} style={{position:'absolute',top:'50%',left:'50%'}} />}>
          <App />
        </Suspense>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}
