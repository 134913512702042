import React from 'react'
import { connect } from 'react-redux'
import {
  Route,
  Redirect,
} from 'react-router-dom'

function PrivateRoute({ username,  children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        username
        ? children
        : <Redirect to={{ pathname: '/', state: { from: location } }} />
      }
    />
  )
}

export default connect(state => {
  return {
    username: state.username,
  }
})(PrivateRoute)