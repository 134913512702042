import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

// You can use isLoading + all Button props
export default function LoadingButton(props) {
  const {
    isLoading,
    disabled,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Button disabled={isLoading || disabled} {...props}>
      </Button>
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}