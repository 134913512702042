export const login = (id, username, roomId, roomKey, guestNumber) => {
  return {
    type: 'LOGIN',
    id,
    username,
    roomId,
    roomKey,
    guestNumber,
  }
}

export const logout = () => {
  return {
    type: 'LOGOUT',
  }
}

const actions = {
  login,
  logout,
}

export default actions