import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Container,
}  from '@material-ui/core'
import { login } from '../store/actions'

import utils from '../utils'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function SignIn({dispatch}) {
  const classes = useStyles()
  const history = useHistory()
  const [name, setName] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const roomKey = urlSearchParams.get('key')
  const guestNumber = urlSearchParams.get('gn')
  const roomId = urlSearchParams.get('rid')
  const username = urlSearchParams.get('name')

  useEffect(() => {
    setCanSubmit(name ? true : false)
  }, [name])

  function submitHandler(e) {
    e.preventDefault()
    toRoom()
  }

  function toRoom() {
    dispatch(login(roomKey, username, roomId, roomKey, guestNumber))
    history.replace('/room/' + utils.roomURLComposer())
  }

  if (username) {
    toRoom()
    return false
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}></Avatar>
        <form className={classes.form} noValidate onSubmit={submitHandler}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Name"
            name="username"
            autoFocus
            onChange={(e) => setName(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!canSubmit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default connect()(SignIn)