import React from 'react'
import { Button } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
const styles = (theme) =>({
  label: {
    flexDirection: 'column',
  },
})

function LabelButton({classes, ...props}) {
  // merge props classes if any
  return (
    <Button classes={{ label: classes.label }} {...props}>
    </Button>
  )
}

export default withStyles(styles)(LabelButton)