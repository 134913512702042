import { useRef, useState } from 'react'
import { Client, LocalStream } from 'ion-sdk-js'
import { IonSFUJSONRPCSignal } from 'ion-sdk-js/lib/signal/json-rpc-impl'

import intent from '../intent'
import utils from '../utils'

export default function useSFU(roomId) {
  const sfuSignal = useRef(null)
  const sfuClient = useRef(null)
  const [localStream, setLocalStream] = useState(null)
  const streams = useRef({})
  const [updatedStreams, setUpdatedStreams] = useState(0)
  const published = useRef(false)
  const hasStream = useRef(false)

  const initSFUConnection = (roomId, username, roomKey, guestNumber, turnServerConfig) => {
    if (sfuSignal.current || sfuClient.current) {
      return
    }

    sfuSignal.current = new IonSFUJSONRPCSignal(intent.openSFUSocket(roomId, username, roomKey, guestNumber))
    sfuClient.current = new Client(sfuSignal.current, Object.assign({}, {
      // codec: 'vp8',
      // iceTransportPolicy: 'relay',
    }, turnServerConfig))

    sfuSignal.current.onopen = onSFUOpen
    sfuClient.current.ontrack = onSFUTrack
  }

  const initStream = () => {
    if (localStream || hasStream.current) {
      return
    }

    hasStream.current = true

    LocalStream.getUserMedia({
      resolution: 'vga', // 'qvga', 'vga', 'shd', 'hd', 'fhd', 'qhd'
      audio: true,
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
      },
      simulcast: false,
    }).then(stream => {
      setLocalStream(stream)
    })
  }

  const onSFUOpen = () => {
    sfuClient.current.join(roomId)
  }

  const onSFUTrack = (track, stream) => {
    console.log('onSFUTrack', stream.id)
    if (track.kind === 'video') {
      stream.preferLayer('high')
      track.onunmute = () => {
        streams.current[stream.id] = stream
        setUpdatedStreams(updatedStreams + Math.random())
      }
    }

    setTimeout(() => {
      utils.setMaxBitrate(sfuClient.current)
    }, 2000)
  }

  const publishStream = () => {
    if (!localStream || published.current) {
      return
    }

    console.log('publish stream', localStream.id)

    published.current = true

    if (!sfuClient.current) {
      return
    }

    sfuClient.current.publish(localStream)
    setLocalStream(localStream)

    setTimeout(() => {
      utils.setMaxBitrate(sfuClient.current)
    }, 2000)
  }

  const muteVideo = (mute) => {
    if (!localStream) {
      return
    }

    if (mute) {
      return localStream.mute('video')
    }

    localStream.unmute('video')
  }

  const muteAudio = (mute) => {
    if (!localStream) {
      return
    }

    if (mute) {
      return localStream.mute('audio')
    }

    localStream.unmute('audio')
  }

  const disconnectSFU = () => {
    sfuClient.current?.close()
    sfuSignal.current?.close()

    sfuClient.current = null
    sfuSignal.current = null

    if (localStream?.getTracks) {
      localStream.getTracks().forEach(t => t.stop())
    }

    setLocalStream(null)
  }

  if (sfuClient.current) {
    sfuClient.current.ontrack = onSFUTrack
  }

  if (sfuSignal.current) {
    sfuSignal.current.onerror = (e) => console.log('sfuSignal error', e)
    sfuSignal.current.onclose = (e) => console.log('sfuSignal close', e)
  }

  return {
    initSFUConnection,
    initStream,
    publishStream,
    muteVideo,
    muteAudio,
    localStream,
    streams,
    disconnectSFU,
    sfuClient,
  }
}