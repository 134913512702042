import {
  Container,
  CssBaseline,
  Grid,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core'
import Background from '../images/background.jpg'

const useStyles = makeStyles({
  root: ({image}) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    height: '100vh',
  }),
})

export default function Reject() {
  const classes = useStyles({image: Background})

  return (
    <Container component="main" className={classes.root}>
      <CssBaseline />
      <Grid container>
        <Grid item xs>
          <Box mt={20}>
            <Typography variant="h3" align="center">Rejected by host!</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}