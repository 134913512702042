import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import NamedRoute from './routing/NamedRoute'
import Home from './pages/Home'
import Room from './pages/Room'
import Reject from './pages/Reject'
import './App.css'

function App() {
  return (
    <Router>
      <Switch>
        <NamedRoute path="/room">
          <Room/>
        </NamedRoute>
        <Route path="/rejected">
          <Reject/>
        </Route>
        <Route path="/">
          <Home/>
        </Route>
      </Switch>
    </Router>
  )
}

export default App
