const setMaxBitrate = (client, maxBitrate) => {
  if (!client) {
    return
  }

  window.sfuClient = client

  if (!maxBitrate || !Number.isInteger(maxBitrate)) {
    maxBitrate = 500_000
  }

  // Set max bitrate by the query parameter
  const urlParams = new URLSearchParams(window.location.search)
  const queryMaxBitrate = urlParams.get('mb')
  if (queryMaxBitrate) {
    maxBitrate = Number.parseInt(queryMaxBitrate, 10)
  }


  if (
    'RTCRtpSender' in window &&
    'setParameters' in window.RTCRtpSender.prototype
  ) {
    const senders = client.transports[0].pc.getSenders()

    senders.forEach((sender) => {
      if (sender.track.kind === 'audio') {
        return
      }

      const parameters = sender.getParameters()

      if (!parameters.encodings) {
        parameters.encodings = [{}]
      }

      if (parameters.encodings.length === 0) {
        parameters.encodings.push({})
      }

      if (parameters.encodings[0].maxBitrate === maxBitrate) {
        return
      }

      parameters.encodings[0].maxBitrate = maxBitrate
      parameters.encodings[0].maxFramerate = 30
      // parameters.encodings[0].scaleResolutionDownBy = 2.0
      parameters.encodings[0].networkPriority = 'high'
      parameters.encodings[0].priority = 'high'

      sender.setParameters(parameters)
        .catch(e => console.error(e))
    })
  }
}

const roomURLComposer = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const maxBitrate = queryParams.get('mb')
  const isInfotainment = queryParams.get('itm')
  const language = queryParams.get('l')

  const components = {}
  const result = []

  if (maxBitrate) {
    components['mb'] = isNaN(parseInt(maxBitrate)) ? 500000 : parseInt(maxBitrate)
  }

  if (!!isInfotainment) {
    components['itm'] = queryParams.get('itm')
  }

  if (language) {
    components['l'] = language
  }

  for (let c in components) {
    result.push(encodeURIComponent(c) + '=' + encodeURIComponent(components[c]))
  }

  if (result.length) {
    return '?' + result.join('&')
  }

  return ''
}

// this js query the media devices, find the Media one suggested by weltech
// then return a async function for VideoProject to set the destination output
// refer to https://github.com/webrtc/samples/blob/gh-pages/src/content/devices/input-output/js/main.js
const findMediaOutput = (deviceInfos) => {
  // Handles being called several times to update labels. Preserve values.
  const audioOutputs = []
  for (let i = 0; i !== deviceInfos.length; ++i) {
    const deviceInfo = deviceInfos[i]
    const id = deviceInfo.deviceId
    if (deviceInfo.kind === 'audiooutput') {
      const label = deviceInfo.label || `speaker ${audioOutputs.length + 1}`
      audioOutputs.push({ id, label })
    }
  }

  const mediaOut = audioOutputs.filter((device) => device.label.toLowerCase().indexOf('media') > -1)
  if (mediaOut.length) {
    return mediaOut[0].id
  } else if (audioOutputs.length) {
    return audioOutputs[0].id
  } else {
    return null
  }
}

const setSinkId = async (element) => {
  const deviceInfoPromise = await navigator.mediaDevices.enumerateDevices()
  const deviceList = await deviceInfoPromise
  const sinkId = findMediaOutput(deviceList)

  if (typeof element.sinkId !== 'undefined' && sinkId !== null) {
    element.setSinkId(sinkId)
      .then(() => {
        console.log(`Success, audio output device attached: ${sinkId}`)
      })
      .catch(error => {
        let errorMessage = error
        if (error.name === 'SecurityError') {
          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`
        }
        console.error(errorMessage)
      })
  } else {
    console.warn('Browser does not support output device selection.')
  }
}

const utils = {
  setMaxBitrate,
  roomURLComposer,
  setSinkId,
}

export default utils