import MicOff from '@material-ui/icons/MicOff'
import Mic from '@material-ui/icons/Mic'
import Chip from '@material-ui/core/Chip'
import { Avatar } from '@material-ui/core'

export default function VideoNameTag(props) {
  const {
    username,
    fullscreen,
    style,
    mutedAudio,
    mutedVideo,
  } = props

  return (
    <div
      className="video-grid-name-tag"
      style={style}
    >
      { mutedVideo &&
          <Avatar
            style={{
              width: '10rem',
              height: '10rem',
              zIndex: fullscreen ? 3000 : 2000,
            }}
          >
            <h1>{username}</h1>
          </Avatar>
      }
      {
        !mutedVideo ?
        <Chip
          icon={mutedAudio ? <MicOff color="secondary" /> : <Mic />}
          label={username}
        ></Chip>
        :
        <Avatar style={{
          marginTop: 10,
        }}>
          {mutedAudio ? <MicOff color="secondary" /> : <Mic />}
        </Avatar>
      }

    </div>
  )
}