import intent from '../intent'

let ws = null
const onMessageChannel = {}
const onOpenChannel = {}
const onCloseChannel = {}
const onErrorChannel = {}

// A hook to maintenance a single websocket instance
const useWebSocket = () => {
  const broadcast = (channel, e) => {
    const subscribers = Object.keys(channel)

    subscribers.forEach(s => {
      if (channel[s]) {
        channel[s](e)
      }
    })
  }

  const onSocketOpen = (e) => broadcast(onOpenChannel, e)
  const onSocketMessage = (e) => broadcast(onMessageChannel, JSON.parse(e.data))
  const onSocketClose = (e) => broadcast(onCloseChannel, e)
  const onSocketError = (e) => broadcast(onErrorChannel, e)

  const connectWebSocket = (roomId, username, roomKey, guestNumber) => {
    if (!ws) {
      ws = new WebSocket(intent.openSocket(roomId, username, roomKey, guestNumber))
      ws.onopen = onSocketOpen
      ws.onmessage = onSocketMessage
      ws.onclose = onSocketClose
      ws.onerror = onSocketError
    }
  }

  const disconnectWebSocket = (id) => {
    if (ws) {
      ws.send(intent.createLeaveIntent(id))
      ws.close(1000)
      ws = null
    }
  }

  // on open
  const subscribeOnOpen = (name, fn) => onOpenChannel[name] = fn
  const unsubscribeOnOpen = (name) => delete onOpenChannel[name]

  // on message
  const subscribeOnMessage = (name, fn) => onMessageChannel[name] = fn
  const unsubscribeOnMessage = (name) => delete onMessageChannel[name]

  // on close
  const subscribeOnClose = (name, fn) => onCloseChannel[name] = fn
  const unsubscribeOnClose = (name) => delete onCloseChannel[name]

  // on error
  const subscribeOnError = (name, fn) => onErrorChannel[name] = fn
  const unsubscribeOnError = (name) => delete onErrorChannel[name]

  const sendWebsocketMessage = (message) => {
    if (!ws || ws.readyState !== WebSocket.OPEN) {
      console.log('fail to send websocket message error :', message)
      return
    }

    console.log('send message to websocket', message)

    ws.send(message)
  }

  return {
    websocket: ws,
    connectWebSocket,
    disconnectWebSocket,
    subscribeOnOpen,
    unsubscribeOnOpen,
    subscribeOnClose,
    unsubscribeOnClose,
    subscribeOnError,
    unsubscribeOnError,
    subscribeOnMessage,
    unsubscribeOnMessage,
    sendWebsocketMessage,
  }
}

export default useWebSocket