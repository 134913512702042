// https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js
import {
  useState,
} from 'react'
// import useConstant from 'use-constant'
// import AwesomeDebouncePromise from 'awesome-debounce-promise'
// import { useAsync } from 'react-async-hook'

// Generic reusable hook
const useDebouncedGetGuestURL = (getGuestURL) => {

  // Handle the input text state
  const [mobilePhone, setMobilePhone] = useState('')
  const [name, setName] = useState('')

  // Debounce the original search async function
  // const debouncedSearchFunction = useConstant(() => AwesomeDebouncePromise(getGuestURL, 500))

  // The async callback is run each time the text changes,
  // but as the search function is debounced, it does not
  // fire a new request on each keystroke
  // const guestURLResult = useAsync(
  //   async () => {
  //     if (mobilePhone.length === 0 || mobilePhone === '852') {
  //       return { data: '' }
  //     } else {
  //       return debouncedSearchFunction(mobilePhone, name)
  //     }
  //   },
  //   [debouncedSearchFunction, mobilePhone, name],
  // )

  // Return everything needed for the hook consumer
  return {
    mobilePhone,
    setMobilePhone,
    name,
    setName,
    // guestURLResult,
  }
}

export default useDebouncedGetGuestURL