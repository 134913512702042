import api from './api'

export const informId = 'informId'
export const updateRoomInfo = 'updateRoomInfo'
export const acceptConnect = 'acceptConnect'
export const sendOffer = 'sendOffer'
export const sendAnswer = 'sendAnswer'
export const sendIceCandidate = 'sendIceCandidate'
export const getTurnServeConfig = 'getTurnServeConfig'
export const disconnect = 'disconnect'
export const requestWhatsapp = 'requestWhatsapp'
export const sentWhatsapp = 'sentWhatsapp'
export const requestEmail = 'requestEmail'
export const decline = 'decline'
export const leave = 'leave'
export const terminate = 'terminate'
// Update user status
export const updateStreamId = 'updateStreamId'
export const muteAudio = 'muteAudio'
export const muteVideo = 'muteVideo'

export const openSocket = (roomId, name, key, guestNumber) => {
  const url = new URL(api.signaling())

  if (!roomId) throw new Error('No room id')
  if (!name) throw new Error('No name')
  if (!key) throw new Error('No key')

  url.searchParams.set('rid', roomId)
  url.searchParams.set('name', name)
  url.searchParams.set('key', key)

  if (guestNumber) {
    url.searchParams.set('gn', guestNumber)
  }

  return url.toString()
}

export const openSFUSocket = (roomId, name, key, guestNumber) => {
  const url = new URL(api.sfu())

  if (!roomId) throw new Error('No room id')
  if (!name) throw new Error('No name')
  if (!key) throw new Error('No key')

  url.searchParams.set('rid', roomId)
  url.searchParams.set('name', name)
  url.searchParams.set('key', key)

  if (guestNumber) {
    url.searchParams.set('gn', guestNumber)
  }

  return url.toString()
}

export const createIntent = (intent, senderId, receiverId, data = {}) => {
  return JSON.stringify({
    intent,
    senderId,
    receiverId,
    data,
  })
}

export const createSendOfferIntent = (senderId, receiverId, offer) => {
  return createIntent(sendOffer,  senderId, receiverId, {
    offer,
  })
}

export const createSendAnswerIntent = (senderId, receiverId, answer) => {
  return createIntent(sendAnswer,  senderId, receiverId, {
    answer,
  })
}

export const createSendIceCandidateIntent = (senderId, receiverId, candidate) => {
  return createIntent(sendIceCandidate,  senderId, receiverId, {
    candidate,
  })
}

export const createGetTurnServeConfigIntent = (senderId) => {
  return createIntent(getTurnServeConfig,  senderId)
}

// userId is the user which in the waiting list and ready to start conversation
export const createAcceptConnectIntent = (senderId, receiverId) => {
  return createIntent(acceptConnect, senderId, receiverId)
}

// userId is the user which will kick by the host
export const createDisconnectUserIntent = (senderId, receiverID) => {
  return createIntent(disconnect, senderId, receiverID)
}

// receiverId no use, guest not here yet
export const createWhatsappIntent = (senderId, receiverId, guestNumber, guestName, language) => {
  return createIntent(requestWhatsapp, senderId, receiverId, {
    guestNumber,
    guestName,
    language,
  })
}

// receiverId no use, guest not here yet
export const createEmailIntent = (senderId, receiverId, guestEmail, guestName, language) => {
  return createIntent(requestEmail, senderId, receiverId, {
    guestEmail,
    guestName,
    language,
  })
}

// receiverId no use, guest not here yet
export const createLeaveIntent = (senderId) => {
  return createIntent(leave, senderId)
}

export const createUpdateStreamIdIntent = (senderId, streamId) => {
  return createIntent(updateStreamId, senderId, '', {
    streamId,
  })
}

export const createUpdateMutedVideoIntent = (senderId, muted) => {
  return createIntent(muteVideo, senderId, '', {
    'mutedVideo': muted,
  })
}

export const createUpdateMutedAudioIntent = (senderId, muted) => {
  return createIntent(muteAudio, senderId, '', {
    'mutedAudio': muted,
  })
}

const intents = {
  openSocket,
  openSFUSocket,

  // intent constants
  informId,
  acceptConnect,
  updateRoomInfo,
  sendOffer,
  sendAnswer,
  sendIceCandidate,
  getTurnServeConfig,
  disconnect,
  requestWhatsapp,
  sentWhatsapp,
  requestEmail,
  decline,
  leave,
  updateStreamId,
  muteAudio,
  muteVideo,
  terminate,

  // create intent functions
  createIntent,
  createAcceptConnectIntent,
  createSendOfferIntent,
  createSendAnswerIntent,
  createSendIceCandidateIntent,
  createGetTurnServeConfigIntent,
  createDisconnectUserIntent,
  createWhatsappIntent,
  createEmailIntent,
  createLeaveIntent,
  createUpdateStreamIdIntent,
  createUpdateMutedVideoIntent,
  createUpdateMutedAudioIntent,
}

export default intents