import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function ParticipantConfirmBox({
  isHost,
  waitingList,
  onControlAccept,
  onControlDecline,
}) {
  const { t } = useTranslation('main')
  // always show the first item, go until the last one?
  if(waitingList.length === 0) {
    return false
  }
  const participant = waitingList[0]
  return (
    <Dialog open={isHost && !!waitingList.length}>
      <DialogTitle>{t('newP.newParticipant')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {participant.name} {t('newP.tryToJoin')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>onControlDecline(participant.id)} color="secondary">
          {t('newP.decline')}
        </Button>
        <Button onClick={()=>onControlAccept(participant.id)} color="primary">
          {t('newP.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
