import { memo, useEffect, useRef} from 'react'
import utils from '../utils'

function VideoProject(props) {
  const {
    stream,
    muted,
  } = props

  const eleRef = useRef(null)

  useEffect(() => {
    eleRef.current.srcObject = stream
    utils.setSinkId(eleRef.current)
    eleRef.current.muted = muted
  })

  return (
    <video
      id={stream.id}
      ref={eleRef}
      muted={muted}
      autoPlay
      playsInline
      width="100%"
      height="100%"
    ></video>
  )
}

export default memo(VideoProject)