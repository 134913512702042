import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SignIn from '../components/SignIn'

import utils from '../utils'

function Home({username}) {
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search)
  const roomID = queryParams.get('rid')

  if (username) {
    history.push('/room/' + utils.roomURLComposer())
    return false
  }

  if (roomID) {
    return <SignIn/>
  }

  return false
}

export default connect(state => ({
  username: state.username,
}))(Home)