import Mic from '@material-ui/icons/Mic'
import MicOff from '@material-ui/icons/MicOff'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import ListIcon from '@material-ui/icons/List'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import TranslateIcon from '@material-ui/icons/Translate'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountBox from '@material-ui/icons/AccountBox'
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide'
import React, { useState } from 'react'

import {
  Box,
  Badge,
  AppBar,
  Toolbar,
  Dialog,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import LabelButton from './LabelButton'
import WhatsappForwarder from './WhatsappForwarder'

import Logo from '../images/logo.png'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    opacity: 0,
    transition: 'opacity .5s 1s ease-out', // add delay when mouse left
    '&.show' : {
      opacity: 1,
      transition: 'opacity .5s ease-out',
    },
    '&:hover' : {
      opacity: 1,
      transition: 'opacity .5s ease-out',
    },
  },
  toolBar: {
    justifyContent: 'space-evenly',
  },
}))

export default function BottomAppBar({
  show,
  isHost,
  displayMute,
  displayDisableVideo,
  onMuteClicked,
  onVideoClicked,
  onNameListClicked,
  onDisconnectClicked,
  onHideBottomBarClicked,

  //
  isWhatsappLoading,
  onSendInvitationClicked,
  roomID,

  //
  waitingCount,

  // self camera
  onSelfCameraClicked,
  displaySelfCamera,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClick = (event) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLanguageSwitch = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'zh' : 'en')
  }

  const { t, i18n } = useTranslation('main')

  return (
    <AppBar color="default" position="fixed" className={
      classes.appBar + (show?' show':'')
    }>
      <Toolbar className={classes.toolBar}>
        <Box>
          <img src={Logo} style={{height:'2rem',width:'auto'}} alt="" />
        </Box>
        <LabelButton onClick={onHideBottomBarClicked}>
          {
            <>
              <KeyboardHideIcon />
              {t('bottomBar.hideBottomBar')}
            </>
          }
        </LabelButton>
        <LabelButton onClick={onSelfCameraClicked}>
          { displaySelfCamera ?
            <>
              <AccountBox />
              {t('bottomBar.hideSelf')}
            </>
            :
            <>
              <AccountBox color="secondary" />
              {t('bottomBar.showSelf')}
            </>
          }
        </LabelButton>
        <LabelButton onClick={onMuteClicked} color="inherit">
          { displayMute ?
            <>
              <Mic />
              {t('bottomBar.mute')}
            </>
            :
            <>
              <MicOff color="secondary" />
              {t('bottomBar.unmute')}
            </>
          }
{/*
VideocamIcon
VideocamOffIcon */}
        </LabelButton>
        <LabelButton onClick={onVideoClicked} color="inherit">
        { displayDisableVideo ?
          <>
            <VideocamIcon />
            {t('bottomBar.disableCamera')}
          </> :
          <>
            <VideocamOffIcon color="secondary" />
            {t('bottomBar.enableCamera')}
          </>
        }
        </LabelButton>
        <LabelButton onClick={onNameListClicked} color="inherit">
          <Badge color="secondary" badgeContent={isHost ? waitingCount : 0}>
            <ListIcon />
          </Badge>
          {t('bottomBar.participants')}
        </LabelButton>
        { isHost &&
        <>
          <LabelButton onClick={handleClick} color="inherit">
            {/* <SearchIcon /> */}
            <PersonAddIcon />
            {t('bottomBar.invite')}
          </LabelButton>
          <Dialog open={open} onClose={handleClose}>
            <WhatsappForwarder
              roomID={roomID}
              isLoading={isWhatsappLoading}
              onSendInvitationClicked={ (guestNumber, guestName, guestEmail)=>{
                // setTimeout(()=>{
                  handleClose()
                  onSendInvitationClicked(guestNumber, guestName, guestEmail)
                // }, 750)
              }}
            />
          </Dialog>
        </>
        }
        <LabelButton onClick={handleLanguageSwitch}>
          <TranslateIcon />
          {t('bottomBar.languageSwitch')}
        </LabelButton>
        <LabelButton onClick={onDisconnectClicked} color="secondary">
          <ExitToAppIcon />
          {t('bottomBar.leave')}
        </LabelButton>
      </Toolbar>
    </AppBar>
  )
}