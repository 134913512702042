import React, { useState } from 'react'
import axios from 'axios'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import LoadingButton from './LoadingButton'
// import QRCode from './QRCode'
import useDebouncedGetGuestURL from '../hooks/useDebouncedGetGuestURL'
import { useTranslation } from 'react-i18next'
import api from '../api'

export default function WhatsappForwarder({
  roomID,
  onSendInvitationClicked,
  isLoading,
}) {
  const { mobilePhone, setMobilePhone, name, setName } = useDebouncedGetGuestURL(
    (mobilePhone, name) =>
      axios.post(api.generateGuestURL(), {
        rid: roomID,
        gn: '852' + mobilePhone,
        name: name,
      }),
  )
  // const [mobilePhone, setMobilePhone] = useState('')
  const [email, setEmail] = useState('')
  // const [name, setName] = useState('')
  const [error, setError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const { t } = useTranslation('main')

  const validateEmail = () => {
    if (!name || !email) {
      return false
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const testResult = email.length === 0 || emailRegex.test(email)
    setEmailError(!testResult)

    return testResult
  }

  const validateWhatsapp = () => {
    if (!name || !mobilePhone) {
      return false
    }

    const phoneRegex = /^\d{8}$/
    const testResult = phoneRegex.test(mobilePhone)
    setError(!testResult)

    return testResult
  }

  // 852 prefix for HK only
  // then if future need like select from list, add here
  const sendWhatsapp = () => {
    onSendInvitationClicked('852' + mobilePhone, name, null)
  }

  const sendEmail = () => {
    onSendInvitationClicked(null, name, email)
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
            >{t('invite.sendLink')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label={t('invite.name')}
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  validateWhatsapp()
                  validateEmail()
                }
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label={t('invite.mobileNo')}
              value={mobilePhone}
              error={error}
              helperText={error ? t('invite.incorrectFormat') : ''}
              fullWidth
              onChange={(e) => {
                setMobilePhone(e.target.value)
                setError(false)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  validateWhatsapp() && sendWhatsapp()
                }
              }}
              type="number"
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label={t('invite.emailAddress')}
              value={email}
              error={emailError}
              helperText={emailError ? t('invite.incorrectFormat') : ''}
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value)
                setEmailError(false)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  validateEmail() && sendEmail()
                }
              }}
            ></TextField>
          </Grid>
          <Divider />
          {/* <Grid item xs={12}>
            { t('invite.sendQR') }
            { guestURLResult.loading && <div>Loading...</div>}
            { guestURLResult.error && <div>{t(guestURLResult.error.message)}</div>}
            { guestURLResult.result &&
              <QRCode qrcodetext={guestURLResult.result.data} />
            }
          </Grid> */}
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <LoadingButton
            variant="outlined"
            disabled={ !name || !mobilePhone}
            isLoading={isLoading}
            onClick={() => validateWhatsapp() && sendWhatsapp()}
          >
            {t('invite.sendWhatsapp')}
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            disabled={ !name || !email}
            isLoading={isLoading}
            onClick={() => validateEmail() && sendEmail() }
          >
            {t('invite.sendEmail')}
          </LoadingButton>
        </Box>
      </CardActions>
    </Card>
  )
}